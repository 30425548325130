import { Search, Icon, Swipe, SwipeItem, Tag, List, Image } from 'vant'
import Login from '../../intercept/Login.vue'
import Share from '../../common/Share.vue'
import Loading from '../../common/Loading.vue'
import LogVisit from '../../common/LogVisit.vue'
import Clue from '../../common/Clue.vue'
import '../../../../assets/icon/font-icon.js'
export default {
    components: {
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Clue: Clue,
        [Search.name]: Search,
        [Icon.name]: Icon,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem,
        [Tag.name]: Tag,
        [List.name]: List,
        [Image.name]: Image
    },
    data () {
        return {
            loadingShow: false,
            finished: false,
            keyword: '',
            classifyCode: '',
            page: { current: 0, size: 20, last: 0 },
            type: '',
            productList: []
        }
    },
    mounted () {
        var query = this.$route.query
        if (query.keyword !== undefined && query.keyword !== '') {
            this.keyword = query.keyword
        }
        this.retrieveProductPage()
        this.$refs.share.default('main')
    },
    methods: {
        search () {
            this.page.current = 0
            this.finished = false
            this.productList = []
            this.retrieveProductPage()
            this.createSearchClue()
        },
        async retrieveProductPage () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var pd = { keyword: this.keyword, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/visa/productAPC/retrieveProductPage', this.$qs.stringify(pd))
                if (res.status === '200') {
                    console.log(res.data)
                    this.productList = this.productList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        detail (code) {
            var key = window.const.global.VISA_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/visa/detail', query: { code: code } })
        },
        createSearchClue () {
            var date = new Date()
            var dt = date.getTime()
            var param = { keyword: this.keyword, url: window.location.href }
            var content = '出国签证搜索'
            this.$refs.clue.createClue('VISA_SEARCH_VISA', dt, JSON.stringify(param), content)
        },
        back () {
            var back = window.sessionStorage.getItem(window.const.global.VISA_SEARCH_INDEX_BACK)
            if (back !== undefined && back !== null && back !== '') {
                window.location.href = back
            } else {
                this.$dialog.alert({
                    title: '提示信息',
                    message: '已返回到最上层'
                })
            }
        }
    }
}
